import React from "react"

import { navigate } from "gatsby"

import AuthWrapper from "../components/auth/AuthWrapper"
import Button from "../components/common/Button"
import Input from "../components/common/Input"
import { toastMessage, TYPE_ERROR } from "../components/common/Toast"
import { AuthContext } from "../store"

const ChangePasswordPage = () => {
  const { isAuthenticated, changePassword } = React.useContext(AuthContext)

  const [oldPassword, setOldPassword] = React.useState("")
  const [newPassword, setNewPassword] = React.useState("")

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate("/")
    }
  }, [isAuthenticated])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (oldPassword === newPassword) {
      toastMessage("Old password and new password cannot be same!", TYPE_ERROR)
      return
    }
    changePassword({ oldPassword, newPassword })
  }

  return (
    <AuthWrapper title="Change Password">
      <form className="form" onSubmit={handleSubmit}>
        <Input
          type="password"
          id="password"
          label="password"
          placeholder="Old Password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          minLength="8"
          isBlock
          required
        />
        <Input
          type="password"
          id="email"
          label="email"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          minLength="8"
          isBlock
          required
        />
        <Button type="submit" color="primary" isBlock>
          Save
        </Button>
      </form>
    </AuthWrapper>
  )
}

export default ChangePasswordPage
